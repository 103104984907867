import Slider from '@vueform/slider'
import constants from "./../Utils/Constants"
import data from './../translation/global.json'
import axios from "axios";
import '@/assets/custom.css'
import '@/assets/rangeslider.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import errorimage from './../assets/errorimage.png'
import errorimage_de from './../assets/errorimage_de.png'
import errorimage_fr from './../assets/errorimage_fr.png'
import errorimage_es from './../assets/errorimage_es.png'
import errorimage_pt from './../assets/errorimage_pt.png'
import errorimage_it from './../assets/errorimage_it.png'

import previewOff from './../assets/turn_en.png'
import previewOff_de from './../assets/turn_de.png'
import previewOff_fr from './../assets/turn_fr.png'
import previewOff_es from './../assets/turn_es.png'
import previewOff_pt from './../assets/turn_pt.png'
import previewOff_it from './../assets/turn_it.png'

import errorimage1 from './../assets/refresh-arrow.png'


import VPip from 'v-pip';


import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import html2canvas from 'html2canvas';



export default {
    name: "About",

    data: function () {
        return {
            auto: 'auto',
            isClicked: false,
            isClickedOn: false,
            isClickedflash: false,
            isClickedcamera: false,
            isClickedflashcolor: false,
            isDisabled: false,
            backgroundColor: '#00b7f1',
            textcolor: 'white',
            textcolorlevel: 'black',
            slidercolor: '#00589D',
            pointerEvents: 'auto',
            ippc_desc: 'Turn your mobile phone into an IP Camera',
            camera_light: 'Camera Light',
            auto_focus: 'Auto Focus',
            switch_camera: 'Switch Camera',
            record_vid: 'Record Video',
            control_mobile: 'Control your mobile camera',
            rotate_cam: 'Rotate Camera',
            full_screen: 'Full Screen',
            zoom_level: 'Zoom Level',
            bright: 'Brightness',
            turn_camera: 'Turn Camera',
            take_sc: 'Take Screenshot',
            imageData: errorimage,
            errorImages: errorimage1,
            myNumber: 50,
            myBrightValue: -12,
            getActiveClients: 0,
            myZoomValue: 0,
            image: "",
            isHidden: true,
            isCalled: false,
            logout: 'Logout',
            minValue: -12,
            maxValue: 100,
            minBrightValue: 0,
            maxBrightValue: 100,
            version: 209, //minimum apk version for web view as of 8/22
        };
    },

    components: {
        Slider,
        VPip,
    },

    created: function () {


        if (localStorage.getItem("flash1") == 1) {
            this.isClickedflash = true
            this.isClickedflashcolor = true
        } else {
            console.log("no");
        }
        
        if (localStorage.getItem("camera1") == 1) {
            this.isClicked = true
            this.isClickedcamera = true
        } else {
            console.log("no");
        }


        if (localStorage.getItem("val42") == 0 && localStorage.getItem("flash") == 1) {

            localStorage.setItem("val42", 1)
            localStorage.setItem("flash1", 0)
            localStorage.setItem("flash", 0);
            this.isClickedflash = false
            this.isClickedflashcolor = false
        } else {
            console.log("did nothing flash");
        }

        if (localStorage.getItem("val") == 0) {


            location.reload();
            this.isClickedOn = true;
            var flashCommand = {
                command: "turncameraoff",
            };
            this.sendCommand(flashCommand);

            localStorage.setItem("val", 1);

        }

        if (localStorage.getItem("val21") == 0) {
            if (localStorage.getItem("cameradisablemessage") == 1) {
                var flashCommandmessage = {
                    command: "turncameraoff",
                };
                this.sendCommand(flashCommandmessage);
                localStorage.setItem("cameradisablemessage", 0)
            }

            if (localStorage.getItem("flashonmessage") == 1) {
                var flashCommand21 = {
                    command: constants.FLASH,
                };
                localStorage.setItem("flashonmessage", 0)
                this.isClickedflash = false
                this.isClickedflashcolor = false
                localStorage.setItem("flash1", 0)
                localStorage.setItem("flashclickstate", "false")
                localStorage.setItem("flashonmessage", 0);
                localStorage.setItem("flash", 0);
            }
            this.sendCommand(flashCommand21);
            if (localStorage.getItem("camera1") == 1) {
                var flashCommand22 = {
                    command: constants.SWITCH_CAMERA,
                };
                localStorage.setItem("cameraonmessage", 0)
                this.isClicked = false
                this.isClickedcamera = false

                localStorage.setItem("cameraclickstate", "false")
                localStorage.setItem("l1", 0)
                localStorage.setItem("camera1", 0)
            }
            this.sendCommand(flashCommand22);

            var flashCommandlo = {
                command: "turncameraoff",
            };
            this.sendCommand(flashCommandlo);
            localStorage.setItem("val21", 1);

        }

        if (localStorage.getItem(constants.TOKEN) == null) {
            this.$router.push("/")
        }

        var languageValue = localStorage.getItem(constants.LANGUAGE_CODE);
        if (languageValue > 0) {
            this.auto = 'none'
        }
        this.ippc_desc = data.TurnyourmobilephoneintoanIPCamera[languageValue]
        this.logout = data.Logout[languageValue]
        this.camera_light = data.CameraLight[languageValue]
        this.auto_focus = data.AutoFocus[languageValue]
        this.SwitchTo = data.SwitchTo[languageValue]
        this.BackCamera = data.BackCamera[languageValue]
        this.FrontCamera = data.FrontCamera[languageValue]
        this.LightOn = data.LightOn[languageValue]
        this.LightOff = data.LightOff[languageValue]
        this.record_vid = data.RecordVideo[languageValue]
        this.rotate_cam = data.RotateCamera[languageValue]
        this.full_screen = data.FullScreen[languageValue]
        this.zoom_level = data.ZoomLevel[languageValue]
        this.bright = data.Brightness[languageValue]
        this.control_mobile = data.Controlyourmobilecamera[languageValue]
        this.turn_camera = data.TurnCamera[languageValue]
        this.take_sc = data.TakeScreenshot[languageValue]
        this.turn_camera_on = data.TurnCameraOn[languageValue]
        this.turn_camera_off = data.TurnCameraOff[languageValue]

        this.checkActiveClients()
        this.getZoomValue()
        this.getBrightness()
        this.getBrightnessLevels()
        this.getZoomLevels()
        this.sendpageversion()
        axios.post("/sendpageversion",
            {
                name: "data",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.COMMANDS,
                data: "sendpageversion",
            }).then(function (response) {
                console.log(response);
            })

        console.log(this.getActiveClients);
        if (localStorage.getItem("getCamValue") == null) {
            localStorage.setItem("getCamValue", 1)
        }
        if (localStorage.getItem("getCamValuek") == 1) {
            var flashCommandk = {
                command: "turncameraoff",
            };
            this.sendCommand(flashCommandk);
            localStorage.removeItem("getCamValuek")
        }


        if (localStorage.getItem("cameraclickstate") == "false" || localStorage.getItem("cameraclickstate") === null) {
            this.isClickedcamera = false;
        }

        var evtSource = new EventSource(
            constants.URL + "/videofeed?ver=" + localStorage.getItem(constants.TOKEN) + "&sse=true"
        );

        var firstTimeBroadcast = true;

        evtSource.addEventListener(
            "message",
            (event) => {
                if (localStorage.getItem(constants.TOKEN)==null) {
                    window.location="/";
                }

                if(firstTimeBroadcast){
                    localStorage.setItem("cameradisablemessage", 0)
                    localStorage.setItem("getCamValue", 1)
                    localStorage.setItem("val", 1)
                    this.backgroundColor = '#00b7f1'
                    this.pointerEvents = 'auto'
                    this.slidercolor = '#00589D'
                    this.textcolor = 'white'
                    this.textcolorlevel = 'black'
                    this.isClickedcamera = false
                    this.isClickedflashcolor = false     
                    this.isClickedOn = false;
                    document.getElementsByName("turnoncamera")[0].parentNode.style.pointerEvents = 'auto';
                    document.getElementsByName("turnoncamera")[0].getElementsByClassName('func-title')[0].innerText = this.turn_camera_off;
                    
                    firstTimeBroadcast = false;
                }
                var isCamOff = localStorage.getItem("getCamValue")
                if (isCamOff == 1) {
                    if (event.data.includes("isZoomBtnClicked")) {
                        var stream_addres = event.data.substring(0, event.data.indexOf("isZoomBtnClicked"));
                        this.imageData = stream_addres;
                    }
                    else {
                        this.imageData = event.data;
                    }

                    var isZoomBtnClicked = event.data.substring(event.data.indexOf("isZoomBtnClicked") + 17, event.data.indexOf("passwordUpdated"));
                    if (isZoomBtnClicked == "1") {
                        this.zoomValuemax();
                    } else if (isZoomBtnClicked == "2") {
                        this.zoomValuemin();
                    }


                    var ispasswordUpdated = event.data.substring(event.data.indexOf("passwordUpdated") + 16);

                    if (ispasswordUpdated == "true") {

                        localStorage.setItem("getCamValuek", 1)
                        //check if flash and switch camera is been clicked
                        // storing a value and switching the camera on page load after password is been updated 
                        localStorage.setItem("val21", 0);
                        //once updated password goes to login page
                        localStorage.removeItem(constants.TOKEN);
                        let lgn_code = localStorage.getItem('language_code');
                        localStorage.clear();
                        localStorage.setItem('language_code',lgn_code);
                        this.deleteAllCookies();
                        this.$router.push("/");
                    }



                } else {
                    var langVal = localStorage.getItem(constants.LANGUAGE_CODE)
                    if (langVal == 0) {
                        this.imageData = previewOff
                    } else if (langVal == 1) {
                        this.imageData = previewOff_de
                    } else if (langVal == 2) {
                        this.imageData = previewOff_fr
                    } else if (langVal == 3) {
                        this.imageData = previewOff_es
                    } else if (langVal == 4) {
                        this.imageData = previewOff_pt
                    } else if (langVal == 5) {
                        this.imageData = previewOff_it
                    } else {
                        this.imageData = previewOff
                    }

                    ispasswordUpdated = event.data.substring(event.data.indexOf("passwordUpdated") + 16);


                    if (ispasswordUpdated == "true") {

                        localStorage.setItem("getCamValuek", 1)
                        //check if flash and switch camera is been clicked
                        // storing a value and switching the camera on page load after password is been updated 
                        localStorage.setItem("val21", 0);
                        //once updated password goes to login page
                        localStorage.removeItem(constants.TOKEN);
                        let lgn_code = localStorage.getItem('language_code');
                        localStorage.clear();
                        localStorage.setItem('language_code',lgn_code);
                        this.deleteAllCookies();
                        this.$router.push("/");
                    }
                }
            },
            false
        );

        evtSource.onerror = (e) => {
            console.log("checking error 1213 plus" + e);
            if (localStorage.getItem(constants.TOKEN)==null) {
                window.location="/";
            }

            firstTimeBroadcast = true;
            var langVal = localStorage.getItem(constants.LANGUAGE_CODE)
            localStorage.setItem("getCamValue", 1)
            if (langVal == 0) {
                this.imageData = errorimage;
            } else if (langVal == 1) {
                this.imageData = errorimage_de;
            } else if (langVal == 2) {
                this.imageData = errorimage_fr;
            } else if (langVal == 3) {
                this.imageData = errorimage_es;
            } else if (langVal == 4) {
                this.imageData = errorimage_pt
            } else if (langVal == 5) {
                this.imageData = errorimage_it
            } else {
                this.imageData = previewOff
            }
            localStorage.setItem("camera1",0)
            setTimeout(function () {
                console.log("check12" + localStorage.getItem(localStorage.getItem(constants.TOKEN)));
                this.isDisabled = true
                this.isClickedcamera = false
                this.isClickedflashcolor = false
                this.backgroundColor = '#468aa1'
                this.slidercolor = '#468aa1'
                this.pointerEvents = 'none'
                this.textcolor = 'white'
                this.textcolorlevel = '#a6a6a6'
                document.getElementsByName("turnoncamera")[0].style.backgroundColor = '#468aa1';
                document.getElementsByName("turnoncamera")[0].parentNode.style.pointerEvents = 'none';
            }.bind(this), 1000);
            false

        }



    },

    methods: {

        sendpageversion() {
            console.log("I git caklled");
            var flashCommand = {
                command: "getversion",
            };
            this.sendCommand(flashCommand);
            console.log("I fininsief");
        },

        handlePIP(e) {
            this.pip = e; // true or false
        },
        handlePipOpenFailure(e) {
            console.log("Video failed to enter Picture-in-Picture mode.", e);
        },
        handlePipExitFailure(e) {
            console.log("Video failed to leave Picture-in-Picture mode.", e);
        },


        logoutIp() {
            var flashCommand = {
                command: constants.LOGOUT,
            };
            this.sendCommand(flashCommand);
            let lgn_code = localStorage.getItem('language_code');
            localStorage.clear();
            localStorage.setItem('language_code',lgn_code);
            this.deleteAllCookies();
            this.$router.push("/");
        },
        checkTokenChanged() {
            setInterval(() => {
                console.log("check12 i am here" + localStorage.getItem(localStorage.getItem(constants.TOKEN)))
            }, 1000);
        },


        flashOn() {

            var isCamOff = localStorage.getItem("getCamValue")
            var isFrontCamera = localStorage.getItem("camera1")
            if (isCamOff == 1 && isFrontCamera==0) {
                var flashCommand = {
                    command: constants.FLASH,
                };
                this.sendCommand(flashCommand);


                this.isClickedflash = !this.isClickedflash
                if (this.isClickedflash == true) {
                    localStorage.setItem("flashonmessage", 1);
                    localStorage.setItem("flash", 1);
                } else {
                    localStorage.setItem("flashonmessage", 0);
                    localStorage.setItem("flash", 0);

                }
                console.log("this.isClicked" + this.isClicked);
                if (this.isClickedflash == true) {
                    this.isClickedflashcolor = true
                    localStorage.setItem("flashclickstate", "true")
                    localStorage.setItem("flash1", 1)


                } else {
                    localStorage.setItem("flashclickstate", "false")
                    localStorage.setItem("flash1", 0)
                    this.isClickedflashcolor = false
                }
            }

        },


        setAutoFocus() {
            var isCamOff = localStorage.getItem("getCamValue")
            if (isCamOff == 1) {
                var flashCommand = {
                    command: constants.AUTO_FOCUS,
                };
                this.sendCommand(flashCommand);
            } else {
                createToast(data.TurnCameraAutoFocus[localStorage.getItem(constants.LANGUAGE_CODE)])
            }
        },

        rotateCamera() {
            var isCamOff = localStorage.getItem("getCamValue")
            if (isCamOff == 1) {
                var flashCommand = {
                    command: constants.ROTATE,
                };
                this.sendCommand(flashCommand);
            } else {
                createToast(data.TurnCameraRotate[localStorage.getItem(constants.LANGUAGE_CODE)])
            }
            var imagescreen=document.getElementsByClassName("display-dimention")[0]
            if(imagescreen.clientHeight>imagescreen.clientWidth){
                imagescreen.style.marginTop='20px';
                imagescreen.style.marginBottom='20px';
            }
            else{
                imagescreen.style.marginTop='0px';
                imagescreen.style.marginBottom='0px';
            }
        },

        switchCamera() {
            var isCamOff = localStorage.getItem("getCamValue")
            if (isCamOff == 1) {
                var flashCommandmat = {
                    command: constants.SWITCH_CAMERA,
                };
                this.sendCommand(flashCommandmat);
                this.myZoomValue=0;
                this.isClicked = !this.isClicked
                console.log("this.isClicked" + this.isClicked);
                if (this.isClicked == true && this.isClickedflash == true) {
                    this.isClickedflash = false
                }
                if (this.isClicked == true) {
                    this.isClickedcamera = true
                    localStorage.setItem("cameraonmessage", 0);
                    localStorage.setItem("cameraclickstate", "true")
                    localStorage.setItem("l1", 1)
                    localStorage.setItem("camera1", 1)
                    if (localStorage.getItem("flash1") == 1) {
                        this.isClickedflash = false
                        this.isClickedflashcolor = false
                        localStorage.setItem("flash1", 0)
                        localStorage.setItem("flashclickstate", "false")
                        localStorage.setItem("flashonmessage", 0);
                        localStorage.setItem("flash", 0);

                    }

                } else {
                    if (localStorage.getItem("flash1") == 1) {
                        this.isClickedflash = false
                        this.isClickedflashcolor = false
                        localStorage.setItem("flash1", 0)
                        localStorage.setItem("flashclickstate", "false")
                        localStorage.setItem("flashonmessage", 0);
                        localStorage.setItem("flash", 0);

                    }

                    localStorage.setItem("cameraonmessage", 1);
                    localStorage.setItem("cameraclickstate", "false")
                    localStorage.setItem("l1", 0)
                    localStorage.setItem("camera1", 0)

                    this.isClickedcamera = false
                }
            } else {
                createToast(data.TurnCameraSwitchCamera[localStorage.getItem(constants.LANGUAGE_CODE)])

            }
        },

        recordVideo() {

            let route = this.$router.resolve({ path: '/VideoRecord' });

            window.open(route.href, '_blank');
        },

        sliderValue() {
            console.log("call sliderValue ");
            var flashCommand = {
                command: constants.GET_WHITE_BALANCE,
                value: this.myNumber
            };
            this.sendCommand(flashCommand);
        },

        zoomValue() {
            var isCamOff = localStorage.getItem("getCamValue")
            if (isCamOff == 1) {
                var flashCommand = {
                    command: constants.SET_ZOOM,
                    value: this.myZoomValue
                };
                this.sendCommand(flashCommand);
            }
        },
        zoomValuemin() {

            var isCamOff = localStorage.getItem("getCamValue")
            var myZoomValue1 = this.myZoomValue - 1
            if (isCamOff == 1) {
                var flashCommand = {
                    command: constants.SET_ZOOM,
                    value: myZoomValue1
                };
                this.sendCommand(flashCommand);
            }
            this.getZoomValue();
            this.getBrightnessLevels();
        },
        zoomValuemax() {

            var isCamOff = localStorage.getItem("getCamValue")
            var myZoomValue2 = this.myZoomValue + 1
            if (isCamOff == 1) {
                var flashCommand = {
                    command: constants.SET_ZOOM,
                    value: myZoomValue2
                };
                this.sendCommand(flashCommand);
            }
            this.getZoomValue();
            this.getBrightnessLevels();
        },


        getZoomValue() {
            console.log("this is bieng called");
            axios(constants.COMMANDS, {
                method: "post",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.COMMANDS,
                data: {
                    command: constants.GET_ZOOM
                },
            })
                .then((response) => {
                    this.myZoomValue = response.data.value;
                    if (response.status == 200) {
                        console.log("succcses");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        getZoomLevels(from_first_time=null) {


            axios(constants.COMMANDS, {
                method: "POST",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.COMMANDS,
                data: {
                    command: "getzoom"
                },
            })
                .then((response) => {
                    console.log(response.data.max);
                    console.log(response.data.min)
                    this.maxValue = response.data.max
                    this.minValue = response.data.min
                    if(from_first_time){
                        this.myZoomValue = response.data.value
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
        getBrightnessLevels(from_first_time=false) {
            console.log("call getBrightnessLevels");

            axios(constants.COMMANDS, {
                method: "POST",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.COMMANDS,
                data: {
                    command: "getblackwhitebalance"
                },
            })
                .then((response) => {
                    this.maxBrightValue = response.data.max
                    this.minBrightValue = response.data.min
                    if(from_first_time){
                        this.myBrightValue = response.data.value;
                    }
                    console.log("the", response.data.value);
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },


        getBrightness() {
            console.log("call getBrightness");

            axios(constants.COMMANDS, {
                method: "post",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.COMMANDS,
                data: {
                    command: constants.GET_WHITE_BALANCE
                },
            })
                .then((response) => {
                    console.log(response);
                    this.myBrightValue = response.data.value;
                    if (response.status == 200) {
                        console.log("succcses");
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                });

        },

        setBrightness() {
            console.log("call123 setBrightness");
            var isCamOff = localStorage.getItem("getCamValue")
            console.log("iscamoff" + isCamOff);
            if (isCamOff == 1) {

                var flashCommand = {
                    command: constants.SET_WHITE_BALANCE,
                    value: this.myBrightValue

                };
                this.sendCommand(flashCommand);
            }
            console.log("brignthnes" + this.myNumber);
        },

        setBrightnessmin() {


            var isCamOff = localStorage.getItem("getCamValue")
            var myBrightValue1 = this.myBrightValue - 5;

            if (isCamOff == 1) {

                var flashCommand = {
                    command: constants.SET_WHITE_BALANCE,
                    value: myBrightValue1

                };
                this.sendCommand(flashCommand);
            }

            this.getBrightness()
            this.getBrightnessLevels()

        },
        setBrightnessmax() {


            var isCamOff = localStorage.getItem("getCamValue")
            var myBrightValue1 = this.myBrightValue + 5;

            if (isCamOff == 1) {

                var flashCommand = {
                    command: constants.SET_WHITE_BALANCE,
                    value: myBrightValue1

                };
                this.sendCommand(flashCommand);
            }

            this.getBrightness()
            this.getBrightnessLevels()

        },


        takeScreenShot() {
            var isCamOff = localStorage.getItem("getCamValue")
            if (isCamOff == 1) {
                console.log("mylogot");
                html2canvas(this.$refs.fullscreen, {
                    backgroundColor: '#ffffff'
                }).then(canvas => {
                    var imgData = canvas.toDataURL("image/jpeg");
                    this.fileDownload(imgData);
                })
            } else {
                createToast(data.TurnCameraScreenshot[localStorage.getItem(constants.LANGUAGE_CODE)])
            }
        },
        cameraTurnOn() {
            this.isClickedOn = !this.isClickedOn
            console.log("hello" + this.isClickedOn);

            if (this.isClickedOn == false) {
                localStorage.setItem("cameradisablemessage", 0)
                localStorage.setItem("getCamValue", 1)
                localStorage.setItem("val", 1)
                this.backgroundColor = '#00b7f1'
                this.pointerEvents = 'auto'
                this.slidercolor = '#00589D'
                this.textcolor = 'white'
                this.textcolorlevel = 'black'
                this.isClickedcamera = false
                this.isClickedflashcolor = false

                console.log("in camera func" + localStorage.getItem("cameraclickstate"));
                if (localStorage.getItem("cameraclickstate") == true) {
                    this.isClickedcamera = false
                    console.log("in camera func 2" + this.isClickedcamera);
                } else {
                    this.isClickedcamera = true
                    console.log("in camera func 3" + this.isClickedcamera);

                }
                if (this.isClicked == false) {
                    this.isClickedcamera = false
                }


                if (localStorage.getItem("flashclickstate") == true) {
                    this.isClickedflashcolor = false
                    console.log("in flash func 2" + this.isClickedflash);
                } else {
                    this.isClickedflashcolor = true
                    console.log("in flash func 3" + this.isClickedflash);
                }
                if (this.isClickedflash == false) {
                    this.isClickedflashcolor = false
                }


            } else {
                localStorage.setItem("getCamValue", 0)
                localStorage.setItem("val", 0)
                localStorage.setItem("cameradisablemessage", 1)

                this.disableItem()
                this.backgroundColor = '#468aa1'
                this.slidercolor = '#468aa1'
                this.pointerEvents = 'none'
                this.textcolor = 'white'
                this.textcolorlevel = '#a6a6a6'


            }
            var flashCommand = {
                command: "turncameraoff",
            };
            this.sendCommand(flashCommand);


        },
        disableItem() {
            this.isDisabled = true
            this.isClickedcamera = false
            this.isClickedflashcolor = false
        },
        check: function (e) {

            if (e.target.checked) {
                localStorage.setItem("getCamValue", 1)
            } else {
                localStorage.setItem("getCamValue", 0)
            }
            console.log(e.target.checked);


            var flashCommand = {
                command: "turncameraoff",
            };
            this.sendCommand(flashCommand);

        },

        fileDownload(downloadUrl) {
            let aLink = document.createElement("a");
            aLink.style.display = "none";
            aLink.href = downloadUrl;
            aLink.download = data.IPPhoneCamera[0] + ".png";
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
        },

        fullScreenPreview() {
            var isCamOff = localStorage.getItem("getCamValue")
            if (isCamOff == 1) {
                var element = this.$refs['fullscreen']
                console.log(element);
                if ("requestFullscreen" in element) {
                    element.requestFullscreen();
                } else if ("webkitRequestFullscreen" in element) {
                    element.webkitRequestFullscreen();
                } else if ("mozRequestFullScreen" in element) {
                    element.mozRequestFullScreen();
                } else if ("msRequestFullscreen" in element) {
                    element.msRequestFullscreen();
                }
            } else {
                createToast(data.TurnCameraFullscreen[localStorage.getItem(constants.LANGUAGE_CODE)])
            }
        },

        sendCommand(currentCommand) {
            axios(constants.COMMANDS, {
                method: "post",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.COMMANDS,
                data: currentCommand,
            })
                .then((response) => {
                    if (response.status == 200) {
                        console.log("succcses");
                    }
                    console.log(response.data['version']);
                    //
                    if (response.data['version']) {
                        if (response.data['version'] < this.version) {
                            document.getElementById("oldversionmsg").style.display = "block";
                        }
                        else {
                            document.getElementById("oldversionmsg").style.display = "none";
                        }
                    }
                })

                .catch((error) => {
                    console.log(error.response);
                });
        },


        checkActiveClients() {
            axios(constants.COMMANDS, {
                method: "post",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/js  on",
                },
                url: constants.COMMANDS,
                data: {
                    "command": "activeClients"
                },
            })
                .then((response) => {
                    if (response.status == 200) {
                        console.log("succcses");
                        console.log("op " + response.data.client_count);
                    }

                    if (response.data.client_count == null) {

                        localStorage.setItem("getCamValuek", 1)
                        //check if flash and switch camera is been clicked
                        // storing a value and switching the camera on page load after password is been updated 
                        localStorage.setItem("val21", 0);
                        //once updated password goes to login page
                        localStorage.removeItem(constants.TOKEN);
                        let lgn_code = localStorage.getItem('language_code');
                        localStorage.clear();
                        localStorage.setItem('language_code',lgn_code);
                        this.deleteAllCookies();
                        this.$router.push("/");
                    }
                    console.log("che=" + response.data.client_count);
                    this.getActiveClients = response.data.client_count;

                })
                .catch((error) => {
                    console.log("logout here k" + error.response.status);
                    //error after stop broadcasting
                    if (error.response.status == 400) {
                        localStorage.setItem("val41", 0)
                        localStorage.setItem("val42", 0)

                    }

                });
        },

        deleteAllCookies() {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        }

    },
};