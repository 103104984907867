<template>
    <body oncontextmenu="return false">
        <div class="app">
            <div class="Background">

                <div class="well top well-top"
                     style="
            background: #00589d;

            padding-top: 0.6% !important;
            padding-bottom: 0.4% !important;

          ">
                    <a style="margin-top:5px;" class="logo-a">
                        <img class="inline vertically-middle logo-img"
                             src="./../assets/logo.png"
                             style="width: calc(4vw + 0.1vh + 2vmin);margin-top:2px;margin-bottom:2px;" />
                    </a>
                    <div class="inline-table vertically-super LeftPadding10" style="padding-bottom:3px;">
                        <p class="margin-zero title-heading  title-heading-about" style="font-size:calc(1vw + 0.1vh + 2vmin);padding-top:0%;padding-bottom:0%;font-weight:500;margin-top:-10px">IP Phone Camera</p>
                        <p class="title-sub-heading title-sub-heading-about" style="font-size:calc(1vw + 0.1vh + 1vmin);padding-bottom:1px;margin:0%;margin-top:-4px">{{ ippc_desc }}</p>
                    </div>
                    <div class="logout-outer-div" style="float: right;  margin-top: 1vw; margin-right: 1.4vw">
                        <div class="logout-button"
                             style="font-size: calc(1vw + 0.1vh + 1vmin); vertical-align: top;padding-left:20px; padding: 3px;width:auto!important; margin-top: 4%;"
                             @click="logoutIp()">
                            <p class="logouttext" style="padding-left: 10px;padding-bottom:0px;margin:0px;padding-right:11px">
                                {{ logout }}
                            </p>
                        </div>
                    </div>
                </div>


                <div class="container-fluid"><div class="container-fluid">
    <p id="oldversionmsg" style="margin-bottom:0px;margin-top:5px;width:50%;text-align:center;color:red;font-size:calc(0.8vw + 0.1vh + 1vmin)">Please update to the latest version of "IP Phone Camera".</p>
    <div>
        <div class="left-div padding-zero-for-360">
            <div style="
                  background: black;
                  margin: 15px;

                  text-align: center;
                  border-style: solid;
                  border-color: white;
                  position:relative;
                ">
                <img ref="fullscreen"
                     :src="imageData"
                     class="display-dimention"
                     style="text-align: center" />
            </div>
        </div>
        <div class="right-div">
            <div style="text-align: center ;" class="right-div-inner">
                <div></div>

                <div class="Main-Left-Container">
                    <span class="func-title controltext"
                          style="color: black; font-size: 14pt;font-weight: 500;">{{ control_mobile }}</span>


                    <div class="func-icons" style="margin: 10px;display:flex">

                        <a @click="isClickedOn ? cameraTurnOn(): cameraTurnOn() ">
                            <div id="func-icon" name="turnoncamera"
                                 :style="isClickedOn ? { 'background-color': '#00b7f1' } : { 'background-color': '#00589d' }"
                                 style="
                          display: inline-block;

                          border-radius: 8%;
                        ">
                                <img id="svg"
                                     class="funcIcon-dimention"
                                     src="./../assets/TurnCameraOn.svg"
                                     style="margin-top: 5px" /><span id="texthover"
                                                                     class="func-title"
                                                                     style="
                            display: block;
                            color: white;
                            overflow-wrap: break-word;
                          ">{{isClickedOn ? turn_camera_on: turn_camera_off}}</span>
                            </div>
                        </a><a :style="{'pointer-events' : pointerEvents }" @click="switchCamera()">
                            <div id="func-icon"
                                 class="margin-left-func-icon"
                                 :disabled="isDisabled"
                                 :style="isClickedcamera ? { 'background-color': '#00589d' } : { 'background-color': backgroundColor }"
                                 style="
                          display: inline-block;
                          border-radius: 8%;

                        ">
                                <img class="funcIcon-dimention"
                                     src="./../assets/switchCamera.png"
                                     style="margin-top: 4px;width:105px;height:100px" /><span id="texthover"
                                                                                              class="func-title"
                                                                                              style="
                            display: block;

                            overflow-wrap: break-word;
                          "
                                                                                              :style="{'color':textcolor}">{{SwitchTo}} <br class="brtag" :style="{'display':auto}" />{{isClicked ?  BackCamera  : FrontCamera }}</span>
                            </div>
                        </a><a :style="{'pointer-events' : pointerEvents }" @click="flashOn()">
                            <div id="func-icon"
                                 class="margin-left-func-icon"
                                 :style="isClickedflashcolor ? { 'background-color': '#00589d' } : { 'background-color': backgroundColor }"
                                 style="
                          display: inline-block;
                         background-color': red!important;
                          border-radius: 8%;
                        ">
                                <img class="funcIcon-dimention"
                                     src="./../assets/flash.png"
                                     style="margin-top: 5px;width:95px;height:95px;" /><span id="texthover"
                                                                                             class="func-title"
                                                                                             style="
                            display: block;

                            overflow-wrap: break-word;
                            margin-top: 2px
                          "
                                                                                             :style="{'color':textcolor}">{{turn_camera}}<br :style="{'display':auto}" />{{isClickedflash ? LightOff : LightOn }}</span>
                            </div>
                        </a>
                    </div>
                    <div class="func-icons" style="margin: 10px;display:flex">
                        <a :style="{'pointer-events' : pointerEvents }" @click="rotateCamera()">
                            <div id="func-icon"
                                 :style="{'background-color':backgroundColor}"
                                 style="
                          display: inline-block;

                          border-radius: 8%;
                        ">
                                <img class="funcIcon-dimention"
                                     src="./../assets/RotateCamera-01.png"
                                     style="margin-top: 5px" /><span id="texthover"
                                                                     class="func-title"
                                                                     style="
                            display: block;

                            overflow-wrap: break-word;
                          "
                                                                     :style="{'color':textcolor}">{{ rotate_cam }}</span>
                            </div>
                        </a><a :style="{'pointer-events' : pointerEvents }" @click="recordVideo()">
                            <div id="func-icon"
                                 class="margin-left-func-icon"
                                 style="
                          display: inline-block;

                          border-radius: 8%;
                        "
                                 :style="{'background-color':backgroundColor}">
                                <img class="funcIcon-dimention"
                                     src="./../assets/record_video.png"
                                     style="margin-top: 5px" /><span id="texthover"
                                                                     class="func-title"
                                                                     style="
                            display: block;

                            overflow-wrap: break-word;
                          "
                                                                     :style="{'color':textcolor}">{{ record_vid }}</span>
                            </div>
                        </a><a :style="{'pointer-events' : pointerEvents }" @click="takeScreenShot()">
                            <div id="func-icon"
                                 class="margin-left-func-icon"
                                 style="
                          display: inline-block;

                          border-radius: 8%;
                        "
                                 :style="{'background-color':backgroundColor}">
                                <img class="funcIcon-dimention"
                                     src="./../assets/TakeScreenshot-01.png"
                                     style="margin-top: 5px" /><span id="texthover"
                                                                     class="func-title"
                                                                     style="
                            display: block;

                            overflow-wrap: break-word;
                          "
                                                                     :style="{'color':textcolor}">{{ take_sc }}</span>
                            </div>
                        </a>
                    </div>

                    <div class="container">
                        <div class="row row-level">
                            <div id="collevelid" class="col col-level" style="margin-right:30px;padding:0px">
                                <ul class="ul-icon ul-zoom" style="width: 100%;padding-bottom:0px!important;margin-bottom:0px!important;padding:0px">
                                    <li class="">
                                        <img class="imgwidth"
                                             src="./../assets/Minus.png"
                                             style="width: 25px; height: 25px;margin-right: 20px;margn-left:19px"
                                             @click="zoomValuemin()" />
                                    </li>
                                    <li style="width: 115px" class="ul-li-slider">
                                        <Slider style="
                                margin-top: 15px;

                                width: 125px;
                                margin-right: 30px !important;
                              "
                                                v-model="myZoomValue"
                                                :min="minValue"
                                                :max="maxValue"
                                                showTooltip="drag"
                                                :tooltips="false"
                                                @change="zoomValue()"
                                                class="slider-data"
                                                :value="myZoomValue*10"
                                                :style="{'pointer-events' : pointerEvents,'--slider-connect-bg': slidercolor,'--slider-handle-bg': slidercolor}" />
                                    </li>
                                    <li class="plus-bright plus-zoom1">
                                        <img class="imgwidth"
                                             src="./../assets/Plus.png"
                                             style="width: 20px; height: 20px;margin-left: 25px"
                                             @click="zoomValuemax()" />
                                    </li>
                                </ul>

                                <span style="

                            overflow-wrap: break-word;
                           font-size:1.1rem;
                            margin-left: 10px;
                            margin-top: 14px;
                            font-weight: 500;
                          "
                                      class="levelSize"
                                      :style="{'color':textcolorlevel}">{{ zoom_level }}</span>
                            </div>
                            <div class="col col-level" style="">
                                <ul class="ul-icon " style="width: 100%;padding-bottom:0px!important;margin-bottom:0px!important;padding:0px">
                                    <li class="">
                                        <img class="imgwidth brightmin"
                                             src="./../assets/BrightnessFull.png"
                                             style="width: 25px; height: 25px;margin-right: 17px;margin-left:21px"
                                             @click="setBrightnessmin()" />
                                    </li>
                                    <li style="width: 115px" class="ul-li-slider">
                                        <Slider style="
                                margin-top: 15px;

                                width: 125px;
                                 margin-right: 30px !important;
                              "
                                                :tooltips="false"
                                                v-model="myBrightValue"
                                                :min="minBrightValue"
                                                :max="maxBrightValue"
                                                showTooltip="focus"
                                                @change="setBrightness()"
                                                class="slider-data"
                                                :style="{'pointer-events' : pointerEvents,'--slider-connect-bg': slidercolor,'--slider-handle-bg': slidercolor }" />
                                    </li>
                                    <li class="plus-bright">
                                        <img class="imgwidth"
                                             src="./../assets/BrightnessEmpty.png"
                                             style="width: 20px; height: 20px;margin-left: 25px"
                                             @click="setBrightnessmax()" />
                                    </li>
                                </ul>

                                <span style="

                            overflow-wrap: break-word;
                            font-size: 12pt;
                            margin-left: 40px;
                            font-weight: 500;
                            font-size:1.1rem
                          "
                                      class="levelSize brighttext"
                                      :style="{'color':textcolorlevel}">

                                    {{ bright }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
            </div>
        </div>
            </div>
    </body>
</template>

<script src="./../js/about.js"></script>
<style src="@vueform/slider/themes/default.css"></style>

